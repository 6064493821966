import { AbilityBuilder, Ability, AbilityClass } from "@casl/ability";

type Actions =
  | "fill"
  | "view"
  | "create"
  | "update"
  | "delete"
  | "change"
  | "import";

export type Subjects =
  | "Clients"
  | "Client"
  | "Roles"
  | "Role"
  | "Buildings"
  | "Building"
  | "Products"
  | "Product"
  | "Plants"
  | "Plant"
  | "Contacts"
  | "Contact"
  | "Components"
  | "Component"
  | "Users"
  | "User"
  | "Permissions"
  | "Permission"
  | "Disturbances"
  | "Disturbance"
  | "Tasks"
  | "Task"
  | "Template"
  | "Password"
  | "Plant Product"
  | "Excelreport"
  | "Traffic Lights"
  | "Traffic Light"
  | "Subtasks"
  | "Setting Tasks"
  | "Resolvedtasks"
  | "Tasksettings"
  | "Self Current Activities"
  | "Other Current Activities"
  | "Disturbances Client"
  | "Self Upcoming Activities"
  | "Other Upcoming Activities"
  | "Download Acintern"
  | "Download Kunden";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export const defineRulesFor = (permissions: any[]) => {
  const { can, rules } = new AbilityBuilder(AppAbility);
  permissions.forEach((permission) => {
    const { name } = permission;
    const parts = name.split("_");
    if (parts.length >= 2) {
      const action = parts[0];
      const subject = parts
        .slice(1)
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      can(action as Actions, subject as Subjects);
    }
  });
  return rules;
};

export const buildAbilityFor = (permissions: any[]): AppAbility => {
  return new AppAbility(defineRulesFor(permissions), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: (object) => object!.type,
  });
};
