import { ClientModel } from "../models/clients/client.model";
import { format, getYear } from "date-fns";
import { GridColDef, GridFilterItem } from "@mui/x-data-grid";
import { MapModel, MarkerModel } from "../models/map/map.model";
import { Marker } from "./app-types";
import {
  showSuccessAlert,
  showErrorAlert,
  showWarningAlert,
} from "../components/shared/alerts";

export const dataToGridColumns = (
  data: Array<object> | undefined,
  fields: string[],
  isMobile: boolean
) => {
  const columns: GridColDef[] = [];
  if (data && data.length > 0) {
    for (let key in data[0]) {
      const temp = {
        field: key,
        headerName: formatHeaderName(key),
        headerClassName: "super-app-theme--header",
        cellClassName: "super-app-theme--cell",
        hide: !fields.includes(key),
        minWidth: isMobile ? 10 : Math.min(key.length * 25, 250),
        flex: 1,
      };
      columns.push(temp);
    }
  }
  return columns;
};

export const transformFilterItemToFilterObject = (
  filterItem: GridFilterItem
): { field: string; value: any } => {
  const { field, value } = filterItem;
  const result: any = {};

  // Add filters.field and filters.value to result if conditions are met
  if (filterItem && typeof value === "string" && value !== "") {
    result[field as string] = value;
  }

  return result;
};

export const extractMarkers = (
  buildingData: MarkerModel[],
  clientData: ClientModel
): Marker[] => {
  const markers: MapModel[] = [];

  if (buildingData) {
    buildingData.forEach((item) => {
      const { address, address_latitude, address_longitude, name } = item;
      if (address_latitude !== null || address_latitude !== null) {
        markers.push({
          address_latitude: address_latitude,
          address_longitude: address_longitude,
          address: `${address} | ${name}`,
        });
      }
    });
  }

  if (clientData) {
    const { address, address_latitude, address_longitude, name } = clientData;
    if (address_latitude !== null || address_latitude !== null) {
      markers.push({
        address_latitude: address_latitude,
        address_longitude: address_longitude,
        address: `${address} | ${name}`,
      });
    }
  }

  return markers;
};

export const filteredObject = (obj: any, excludedKeys: string[]) => {
  const filteredObj: any = {};

  for (let key in obj) {
    if (excludedKeys.includes(key)) {
      continue; // Skip excluded keys
    }

    if (typeof obj[key] === "object" && obj[key] !== null) {
      // Recursively filter nested objects
      filteredObj[key] = filteredObject(obj[key], excludedKeys);
    } else {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
};

export const formatHeaderName = (name: string) => {
  const words = name.split("_");
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return formattedWords.join(" ");
};

export const getCookieValue = (name: string | any[]) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

// get values from cookie
export const getCookieValues = (
  cookieName: string,
  propertyName: string
): string | null => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      const cookieValue = cookie.substring(cookieName.length + 1);
      const cookieObject = JSON.parse(decodeURIComponent(cookieValue));
      const propertyValue = cookieObject[propertyName];
      return propertyValue;
    }
  }
  return null;
};
export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const equalsPos = cookie.indexOf("=");
    const name = equalsPos > -1 ? cookie.substr(0, equalsPos) : cookie;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
};

export const userToken = getCookieValues("_auth_state", "access_token");
export const userFullName = getCookieValues("_auth_state", "fullName");
export const userCookieId = getCookieValues("_auth_state", "id");
export const userRole = getCookieValues("_auth_state", "role");

// Used with form when update values -> only send the updated fields
export const getChangedFormValues = <T extends Record<string, any>>(
  values: T,
  initialValues: T
) => {
  return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
    const hasChanged =
      JSON.stringify(initialValues[key as keyof T]) !== JSON.stringify(value);
    if (hasChanged) {
      acc[key as keyof T] = value;
    }

    return acc;
  }, {});
};

export const showNotification = async (
  type: string | undefined,
  body: string | undefined,
  title?: string | undefined,
  icon?: boolean | undefined,
  isHtml: boolean | undefined = false
) => {
  switch (type) {
    case "urgent":
      await showErrorAlert(body, title, icon, isHtml);
      break;
    case "normal":
      await showSuccessAlert(body, title, icon, isHtml);
      break;
    case "alert":
      await showWarningAlert(body, title, icon, isHtml);
      break;
    default:
      break;
  }
};

export function formatDateToShortDate(date: any) {
  if (date instanceof Date && !isNaN(date.getTime())) {
    return format(date.getTime(), "yyyy-MM-dd");
  } else {
    return null;
  }
}

export function formatDate(input: string | null): string | null {
  if (!input) return null;

  const datePart = input.split(" ")[0];
  return datePart;
}

export function formatTimeToShortTime(time: any) {
  return format(time, "HH:mm");
}

export function formatYear(date: any) {
  return getYear(date);
}

export function matchNumbersToDays(commaSeparatedString: string) {
  // Map of English day abbreviations to German day names
  const dayMapping: { [key: string]: string } = {
    SA: "Samstag",
    SU: "Sonntag",
    MO: "Montag",
    TU: "Dienstag",
    WE: "Mittwoch",
    TH: "Donnerstag",
    FR: "Freitag",
  };

  // Split the input string by commas and map each abbreviation to the German day name
  const selectedDays = commaSeparatedString
    .split(",")
    .map((abbr) => dayMapping[abbr.trim().toUpperCase()] || "Invalid Day");

  return selectedDays.join(", ");
}

// Map key with value
export const keyToValue = (key: string) => {
  // Define a mapping of keys to values
  const keyToValueMap: any = {
    ph_value_at_20c: "pH-Wert bei 20°C",
    conductivity_at_25c: "elektrische Leitfähigkeit bei 25°C",
    temperature: "Temperatur",
    total_hardness: "Gesamthärte",
    alkalinity: "Alkalität",
    sodium: "Natrium",
    magnesium: "Magnesium",
    chloride: "Chlorid",
    sulfates: "Sulfate",
    total_phosphate_po4: "Phosphat gesamt als PO4",
    date_of_sampling: "Datum der Probennahme",
    iron: "Eisen",
    copper: "Kupfer",
    zinc: "Zink",
    chrome: "Chrom",
    conditioning_agent: "Konditionierungsmittel",
    desalting_machine_fez: "FWZ am Absalzautomat",
    desalination_machine_awz: "AWZ am Absalzautomat",
    fresh_water_meter: "Frischwasserzähler",
    waste_water_meter: "Abwasserzähler",
    biocide_consumed_quantity: "Verbrauchte Menge Biozid",
    hardness_stabilizer_consumed_quantity:
      "Verbrauchte Menge Härtestabilisator",
    ph_value_20c: "pH-Wert bei 20°C",
    ph_value_desalination_machine: "pH-Wert am Absalzautomat",
    conductivity_25c: "elektrische Leitfähigkeit bei 25°C",
    conductivity_continuous_blowdown_machine: "Leitfähigkeit am Absalzautomat",
    m_value: "M-Wert",
    chloride_content: "Chloridgehalt",
    iron_content: "Eisengehalt",
    product_content: "Produktgehalt",
    pseudomonas: "Pseudomonas",
    legionella: "Legionellen",
    colony_count_at_22c: "Koloniezahl bei 22°C",
    colony_count_at_36c: "Koloniezahl bei 36°C",
    low_risk_min: "Hohes Risiko",
    low_warning_min: "Risiko",
    normal_min: "Normal von",
    high_warning_min: "Normal bis",
    high_risk_min: "Risiko",
    high_risk_max: "Hohes Risiko",
    nitrate: "Nitrat",
    sulphite: "Sulfit",
    permanganate_index_o2: "Permanganatindex als O2",
    ammonium: "Ammonium",
    hydrocarbons_wa: "Kohlenwasserstoffe (Wa)",
    toc: "TOC",
    lead: "Blei",
    nickel: "Nickel",
    turbidity: "Trübung",
    nitrite: "Nitrit",
    aox: "AOX",
    acid_capacity_ks8_2: "Säurekapazität KS8,2",
    acid_capacity_up_to_ph_4_3: "Säurekapazität bis pH 4,3 (Ks 4,3)",
    potassium: "Kalium",
    aluminum: "Aluminium",
    manganese: "Mangan",
    base_capacity_kb82: "Basekapazität Kb8,2",
    silicon_sio2: "Silicium als SiO2",
    silicic_acid_sio2: "Kieselsäure (SiO2)",
    molybdenum: "Molybdän",
    ortho_phosphate: "ortho-Phosphat",
    calcium: "Calcium",
    carbonate_hardness: "Karbonathärte",
    total_hardness_cao: "Gesamthärte (als CaO)",
    dip_slide_wert: "Dips-Slide Wert",
    silicate: "Silikat",
    deha: "DEHA",
    phosphate: "o-Phosphat",
    sulfite: "Sulfit",
    p_value: "p-Wert",
    residual_hardness: "Resthärte",
    // Add more key-value pairs as needed
  };

  // Check if the inputText exists in the mapping, return the corresponding value
  if (keyToValueMap.hasOwnProperty(key)) {
    return keyToValueMap[key];
  }

  // Return a default value or null if the inputText is not found
  return "Not found";
};

export const adjustYear = (year: number | undefined) => {
  if (year) {
    const date = new Date();
    date.setFullYear(year);
    return date;
  }
  return "";
};

export const getDates = (data: any = [], key: string) => {
  let timeStamp = 1570606650000;
  let dates = [];

  for (let i = 0; i < data.length; i++) {
    timeStamp = new Date(data[i].resolve_date).getTime();
    if (data[i][key] !== null && data[i][key] !== undefined) {
      const innerArr = [timeStamp, data[i][key]];
      dates.push(innerArr);
    }
  }
  return dates;
};

export const formatNumberType = (value: number) => {
  const maximumFractionDigits = 5;
  if (value) {
    return new Intl.NumberFormat("de-DE", {
      maximumFractionDigits: maximumFractionDigits,
    }).format(value);
  }
  return "-  ";
};

export const getUnchangedRowsWithOldValues = (oldData: any, newData: any) => {
  const unchangedRows = oldData.map((oldRow: any) => {
    const newRow = newData.find(
      (row: any) => row.parameter_name === oldRow.parameter_name
    );

    if (!newRow) {
      // Row not found in new data, consider it as unchanged with all properties
      return { ...oldRow };
    }

    // Compare each property value
    const properties = Object.keys(oldRow);
    const unchangedProps = properties.filter(
      (property) =>
        property !== "parameter_label" && oldRow[property] === newRow[property]
    );

    if (unchangedProps.length === properties.length) {
      // All properties are unchanged, return the old row with values from oldData
      return { ...oldRow };
    }

    return { ...newRow }; // Some properties have changed, keep the new row
  });

  return unchangedRows;
};

export const getChangedRows = (oldData: any, newData: any) => {
  const changedRows = [];

  for (let i = 0; i < oldData.parameters.length; i++) {
    const oldRow = oldData.parameters[i];
    const newRow = newData.parameters.find(
      (row: any) => row.parameter_name === oldRow.parameter_name
    );

    if (!newRow) {
      // Row not found in new data, consider it as changed with all properties
      changedRows.push({ ...oldRow });
    } else {
      // Compare each property value
      const properties = Object.keys(oldRow);
      const changedProps = properties.filter(
        (property) => oldRow[property] !== newRow[property]
      );

      if (changedProps.length > 0) {
        const changedRow = { ...newRow };
        changedProps.forEach((prop) => {
          changedRow[prop] = newRow[prop];
        });
        changedRows.push(changedRow);
      }
    }
  }

  return { parameters: changedRows };
};

export const parseGermanFloat = (input: any) => {
  // Ensure input is a string
  let str = input.toString();

  // Remove dots and commas that are not part of the decimal separator
  const cleanedString = str.replace(/[^\d,-]/g, "");

  // Replace commas with dots for proper parsing
  const dotSeparatedString = cleanedString.replace(/,/g, ".");

  // Parse the float
  const floatValue = parseFloat(dotSeparatedString);

  return floatValue;
};
