import React, { FunctionComponent } from "react";
import { BreadcrumbData } from "use-react-router-breadcrumbs";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Box, useTheme } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { tokens } from "../layout/theme";
import useBreadcrumbStore, {
  BreadcrumbItem,
} from "../../stores/store-breadcrumbs";
interface BreadcrumbItemsProps {
  breadcrumbs: BreadcrumbData<string>[];
}

const BreadcrumbItems: FunctionComponent<unknown> = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const breadcrumb = useBreadcrumbStore((state: any) => state.items);
  const disabledBreadcrumbItems = ["Kunden", 'Gebäude', 'Anlage'];
  return (
    <>
      {breadcrumb && breadcrumb.length > 2 && (
        <Box margin="20px 0px">
          <Breadcrumbs separator="›">
            <Link href="/" sx={{ color: colors.primary[100] }}>
              <HomeIcon sx={{ fontSize: 18 }} />
            </Link>
            {breadcrumb.filter((item: BreadcrumbItem) => !disabledBreadcrumbItems.includes(item.title)).slice(1).map((item: BreadcrumbItem, index: number) => (
              <Link
                key={index}
                underline="none"
                sx={{
                  fontSize: "16px",
                  // index === breadcrumb.length - 2 cuz we're showing an icon instead of showing Home
                  color:
                    index === breadcrumb.length - 2
                      ? colors.primary[300]
                      : colors.primary[100],
                }}
                href={index === breadcrumb.length - 2 ? undefined : item.path}
              >
                {item.title}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>
      )}
    </>
  );
};

export default BreadcrumbItems;
