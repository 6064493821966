import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { fetchUserPermissions } from "../../api/permissions";
import { buildAbilityFor, AppAbility } from "../../casl/ability";
import { token } from "../../api";
import { AxiosError } from "axios";
import { Permission } from "../../utils/app-types";
import { showErrorAlert } from "../shared/alerts";
import { AbilityContext } from "../../casl/Can";
import { useIsAuthenticated } from "react-auth-kit";

const paths = [
  "/login",
  "/signup",
  "/reset-password",
  "/forgot-password",
  "/set-password",
  "/imprint",
];

interface UserPermissionsProps {
  children: ReactNode;
}

const UserPermissions: FunctionComponent<UserPermissionsProps> = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate: NavigateFunction = useNavigate();

  const { children } = props;

  const [ability, setAbility] = useState<AppAbility | undefined>();

  const location = useLocation();

  const checkLocation = () => {
    return (
      paths.includes(location.pathname) ||
      location.pathname.startsWith("/api/auth/email/verify")
    );
  };

  const isLanding = checkLocation();

  const fetchPermissions = async () => {
    try {
      const permissionsData = await fetchUserPermissions(token);
      setAbility(buildAbilityFor(permissionsData.data as Permission[]));
    } catch (error: AxiosError | any) {
      const { data } = error.response;
      showErrorAlert(data.message);
    }
  };

  const redirectUser = () => {
    const authenticated = isAuthenticated();
    if (location.pathname === "/" && authenticated) {
      navigate("/dashboard");
    }
    if (!authenticated) {
      navigate("/login");
    }
  };

  const BootstrapUser = async () => {
    if (!isLanding) {
      if (isAuthenticated()) {
        await fetchPermissions();
      }
      redirectUser();
    }
  };

  useEffect(() => {
    BootstrapUser();
  }, [isAuthenticated()]); // Call the function with dependency on isAuthenticated to ensure it's only called once or when authentication status changes

  if (isLanding) {
    return (
      <>
        <main className="content no-margin">{children}</main>
      </>
    );
  }

  return (
    <>
      {ability && (
        <AbilityContext.Provider value={ability}>
          {children}
        </AbilityContext.Provider>
      )}
    </>
  );
};

export default UserPermissions;
