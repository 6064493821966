import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import { tokens } from "../layout/theme";
import GetAppIcon from "@mui/icons-material/GetApp";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { fileCategories } from "../../data/download-lists";

const ACInternDownload: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Paper elevation={5}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={colors.primary[400]}
        borderRadius="5px"
        marginTop="20px"
      >
        <Box
          mx="20px"
          mt="40px"
          mb="10px"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "93vh",
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ mb: "2px" }}>
            Dateien zum Herunterladen
          </Typography>
          <List>
            {fileCategories.map((category, index) => (
              <Accordion
                sx={{ backgroundColor: colors.primary[400] }}
                disableGutters
                key={`Accordion-item-${index}`}
              >
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                >
                  <Typography variant="h5">{category.germanTitle}</Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ backgroundColor: colors.primary[500] }}>
                  {category.files && (
                    <List>
                      {category.files.map((file, fileIndex) => (
                        <ListItem
                          key={fileIndex}
                          sx={{
                            listStyle: "outside",
                            textDecoration: "none",
                            width: "fit-content",
                          }}
                          component="a"
                          href={file.fileUrl}
                          download
                        >
                          <ListItemText
                            sx={{
                              flex: "none",
                              color:
                                theme.palette.mode === "dark"
                                  ? "white"
                                  : "black",
                            }}
                            primary={file.fileName}
                          />
                          <IconButton
                            edge="end"
                            aria-label="download"
                            href={file.fileUrl}
                            download
                          >
                            <GetAppIcon />
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                  {category.subSections &&
                    category.subSections.map((subSection, subIndex) => (
                      <Accordion
                        key={`sub-${subIndex}-${category.germanTitle}`}
                        sx={{
                          backgroundColor: colors.primary[400],
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<GridExpandMoreIcon />}
                          aria-controls={`sub-panel-${subIndex}-content`}
                          id={`sub-panel-${subIndex}-header`}
                        >
                          <Typography variant="subtitle1">
                            {subSection.title}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails
                          sx={{
                            backgroundColor: colors.primary[500],
                          }}
                        >
                          <List>
                            {subSection.files.map((file, fileIndex) => (
                              <ListItem
                                key={fileIndex}
                                sx={{
                                  listStyle: "outside",
                                  textDecoration: "none",
                                  fontSize: 18,
                                  width: "fit-content",
                                }}
                                component="a"
                                href={file.fileUrl}
                                download
                              >
                                <ListItemText
                                  sx={{
                                    flex: "none",
                                    color:
                                      theme.palette.mode === "dark"
                                        ? "white"
                                        : "black",
                                  }}
                                  primary={file.fileName}
                                />
                                <IconButton
                                  edge="end"
                                  aria-label="download"
                                  href={file.fileUrl}
                                  download
                                >
                                  <GetAppIcon />
                                </IconButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
        </Box>
      </Box>
    </Paper>
  );
};

export default ACInternDownload;
