import { GridColDef } from "@mui/x-data-grid";
import { formatDateToShortDate } from "../../utils/helper";
import { TaskType } from "../../models/tasks/task-status.enum";

export const columns: GridColDef[] = [
  {
    field: "client_name",
    headerName: "Kunde",
    flex: 1,
  },
  {
    field: "created_by",
    headerName: "Aufgabenersteller",
    flex: 1,
  },
  {
    field: "building_name",
    headerName: "Gebäude",
    flex: 1,
  },
  {
    field: "plant_name",
    headerName: "Anlage",
    flex: 1,
  },
  {
    field: "sub_task_type",
    headerName: "Aufgabenart",
    flex: 1,
  },
  {
    field: "due_date",
    headerName: "Enddatum",
    flex: 1,
    valueGetter: ({ row, value }) => {
      return `${value ? formatDateToShortDate(new Date(value)) : null}`
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
  },
];
