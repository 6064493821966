import Box from "@mui/joy/Box";
import Can, { AbilityContext } from "../../casl/Can";
import ColorSchemeToggle from "./color-scheme-toggle";
import Divider from "@mui/joy/Divider";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import NotificationIcon from "../notifications/notification-icon";
import React, { useContext, useState } from "react";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import useScript from "../../utils/useScript";
import useSocketStore from "../../stores/store-socket";
import { closeSidebar } from "./utils";
import { userFullName } from "../../utils/helper";
import { KeyboardArrowUp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { socketLogout } from "../../configs/socket";
import { useSignOut } from "react-auth-kit";
import useBreadcrumbStore from "../../stores/store-breadcrumbs";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { userRole } from "../../utils/helper";
import aqualogo from "../../assets/images/aqualogo.png";
/* eslint-disable jsx-a11y/anchor-is-valid */

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export default function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsedDownload, setIsCollapsedDownload] = useState(true);
  const navigate: NavigateFunction = useNavigate();
  const status = useScript(`https://unpkg.com/feather-icons`);
  const signOut = useSignOut();
  const [index, setIndex] = useState(0);
  const name = userFullName;
  const { socketRefCurrentState } = useSocketStore();
  const profilePath = `/clients/${userRole}`;
  const ability = useContext(AbilityContext);
  console.log(ability, "ability");

  const handleLogout = () => {
    try {
      if (socketRefCurrentState) {
        // Close the WebSocket connection
        socketRefCurrentState.close();
      }

      // This set time out is used to fix a problem related to auth library when sign out
      // Https://github.com/react-auth-kit/react-auth-kit/issues/1193
      setTimeout(() => {
        signOut();
        navigate("/login", { replace: true });
      }, 500);

      socketLogout();
    } catch (error) {
      // Handle any errors that might occur during the logout process
      console.error("Error during logout:", error);
    }
  };

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace();
    }
  }, [status]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleCollapseDownload = () => {
    setIsCollapsedDownload(!isCollapsedDownload);
  };

  const resetBreadcrumb = useBreadcrumbStore(
    (state: any) => state.resetBreadcrumb
  );

  return (
    <>
      <GlobalStyles
        styles={{
          "[data-feather], .feather": {
            color: "var(--Icon-color)",
            margin: "var(--Icon-margin)",
            fontSize: "var(--Icon-fontSize, 20px)",
            width: "1em",
            height: "1em",
          },
        }}
      />
      <Sheet
        className="Sidebar"
        sx={{
          bgcolor: "background.body",
          position: {
            xs: "fixed",
            lg: "sticky",
          },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
            lg: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 10000,
          height: "100dvh",
          width: "var(--Sidebar-width)",
          top: 0,
          px: 1.5,
          py: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider",
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Sidebar-width": "224px",
              [theme.breakpoints.up("lg")]: {
                "--Sidebar-width": "256px",
              },
            },
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: "fixed",
            zIndex: 9998,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "background.body",
            opacity: "calc(var(--SideNavigation-slideIn, 0) - 0.2)",
            transition: "opacity 0.4s",
            transform: {
              xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
              lg: "translateX(-100%)",
            },
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <img
            src={aqualogo} // Replace with the path to your logo image
            alt="Logo"
            style={{
              maxHeight: 30,
              maxWidth: "100%",
              marginRight: 1,
              padding: 3,
            }}
          />

          <ColorSchemeToggle sx={{ ml: "auto" }} />
          <NotificationIcon />
        </Box>
        <Box
          sx={{
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <List
            sx={{
              "--ListItem-radius": "8px",
              "--List-gap": "4px",
              "--List-nestedInsetStart": "40px",
            }}
          >
            <ListItem>
              <ListItemButton
                selected={index === 0}
                onClick={() => {
                  setIndex(0);
                  resetBreadcrumb();
                }}
                role="menuitem"
                component={Link}
                to="/dashboard"
              >
                <ListItemDecorator>
                  <i data-feather="home" />
                </ListItemDecorator>
                <ListItemContent>Home</ListItemContent>
              </ListItemButton>
            </ListItem>
            {/* <ListItem>
              <ListItemButton
                selected={index === 1}
                onClick={() => {
                  setIndex(1);
                  resetBreadcrumb();
                }}
                role="menuitem"
                component={Link}
                to="/messenger"
              >
                <ListItemDecorator>
                  <i data-feather="message-square" />
                </ListItemDecorator>
                <ListItemContent>Nachrichten</ListItemContent>
              </ListItemButton>
            </ListItem> */}
            <Can I="view" a="Clients">
              <ListItem>
                <ListItemButton
                  selected={index === 4}
                  onClick={() => {
                    setIndex(4);
                    resetBreadcrumb();
                  }}
                  role="menuitem"
                  component={Link}
                  to="/clients"
                >
                  <ListItemDecorator>
                    <i data-feather="users" />
                  </ListItemDecorator>
                  <ListItemContent>Kunden</ListItemContent>
                </ListItemButton>
              </ListItem>
            </Can>
            <Can I="view" a="Products">
              <ListItem>
                <ListItemButton
                  selected={index === 5}
                  onClick={() => {
                    setIndex(5);
                    resetBreadcrumb();
                  }}
                  role="menuitem"
                  component={Link}
                  to="/products"
                >
                  <ListItemDecorator>
                    <LocalMallIcon />
                  </ListItemDecorator>
                  <ListItemContent>Produkte</ListItemContent>
                </ListItemButton>
              </ListItem>
            </Can>
            {(ability.can("view", "Roles") || ability.can("view", "Users")) && (
              <ListItem nested>
                <ListItemButton onClick={toggleCollapse}>
                  <ListItemDecorator>
                    <i data-feather="shield" />
                  </ListItemDecorator>
                  <ListItemContent>Verwaltung</ListItemContent>
                  <KeyboardArrowUp
                    style={{
                      transform: isCollapsed ? "rotate(-180deg)" : "none",
                    }}
                  />
                </ListItemButton>
                <List sx={{ display: isCollapsed ? "none" : "block" }}>
                  <Can I="view" a="Roles">
                    <ListItem>
                      <ListItemButton
                        selected={index === 6}
                        onClick={() => {
                          setIndex(6);
                          resetBreadcrumb();
                        }}
                        role="menuitem"
                        component={Link}
                        to="/roles"
                        variant="soft"
                      >
                        Rolle
                      </ListItemButton>
                    </ListItem>
                  </Can>
                  <Can I="view" a="Users">
                    <ListItem>
                      <ListItemButton
                        selected={index === 7}
                        onClick={() => {
                          setIndex(7);
                          resetBreadcrumb();
                        }}
                        role="menuitem"
                        component={Link}
                        to="/users"
                        variant="soft"
                      >
                        Benutzer
                      </ListItemButton>
                    </ListItem>
                  </Can>
                </List>
              </ListItem>
            )}
            <ListItem>
              <ListItemButton
                selected={index === 8}
                onClick={() => {
                  resetBreadcrumb();
                  setIndex(8);
                }}
                role="menuitem"
                component={Link}
                to="/activity"
              >
                <ListItemDecorator>
                  <i data-feather="briefcase" />
                </ListItemDecorator>
                <ListItemContent>Zugeteilte Aufgaben</ListItemContent>
              </ListItemButton>
            </ListItem>
            {userRole && (
              <ListItem>
                <ListItemButton
                  selected={index === 10}
                  onClick={() => {
                    setIndex(10);
                    resetBreadcrumb();
                  }}
                  role="menuitem"
                  component={Link}
                  to={profilePath}
                >
                  <ListItemDecorator>
                    <i data-feather="user" />
                  </ListItemDecorator>
                  <ListItemContent>Mein Profil</ListItemContent>
                </ListItemButton>
              </ListItem>
            )}
            <ListItem nested>
              <ListItemButton onClick={toggleCollapseDownload}>
                <ListItemDecorator>
                  <i data-feather="download" />
                </ListItemDecorator>
                <ListItemContent>Download Area</ListItemContent>
                <KeyboardArrowUp
                  style={{
                    transform: isCollapsedDownload ? "rotate(-180deg)" : "none",
                  }}
                />
              </ListItemButton>
              <List sx={{ display: isCollapsedDownload ? "none" : "block" }}>
                <Can I="view" a="Download Acintern">
                  <ListItem>
                    <ListItemButton
                      selected={index === 11}
                      onClick={() => {
                        setIndex(11);
                        resetBreadcrumb();
                      }}
                      role="menuitem"
                      component={Link}
                      to="/acintern-download"
                      variant="soft"
                    >
                      AC intern
                    </ListItemButton>
                  </ListItem>
                </Can>
                <Can I="view" a="Download Kunden">
                  <ListItem>
                    <ListItemButton
                      selected={index === 12}
                      onClick={() => {
                        setIndex(12);
                        resetBreadcrumb();
                      }}
                      role="menuitem"
                      component={Link}
                      to="/kunden-downloads"
                      variant="soft"
                    >
                      Kunden Downloads
                    </ListItemButton>
                  </ListItem>
                </Can>
              </List>
            </ListItem>
          </List>
          <List
            sx={{
              mt: "auto",
              flexGrow: 0,
              "--ListItem-radius": "8px",
              "--List-gap": "8px",
            }}
          >
            <ListItem>
              <ListItemButton
                selected={index === 9}
                onClick={() => {
                  setIndex(9);
                  resetBreadcrumb();
                }}
                role="menuitem"
                component={Link}
                to="/settings"
              >
                <ListItemDecorator>
                  <i data-feather="settings" />
                </ListItemDecorator>
                <ListItemContent>Einstellungen</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                selected={index === 10}
                onClick={() => {
                  setIndex(10);
                  resetBreadcrumb();
                }}
                role="menuitem"
                component={Link}
                to="/impressum"
              >
                <ListItemDecorator>
                  <i data-feather="info" />
                </ListItemDecorator>
                <ListItemContent>Impressum</ListItemContent>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography fontSize="sm" fontWeight="lg">
              {name}
            </Typography>
          </Box>
          <IconButton onClick={handleLogout} variant="plain" color="neutral">
            <i data-feather="log-out" />
          </IconButton>
        </Box>
      </Sheet>
    </>
  );
}
