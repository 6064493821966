import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
  Theme,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetAppIcon from "@mui/icons-material/GetApp";
import { tokens } from "../layout/theme";
import { FileCategoryKunden } from "../../models/download-model";
import {
  brochureSection,
  coraconCategories,
  industriwasserCategories,
} from "../../data/download-lists";

const renderFileCategoryList = (
  categories: FileCategoryKunden[],
  theme: Theme,
  type: "nested" | "normal",
  color: string,
  secondaryColor: string
) => {
  return (
    <>
      {type === "nested" ? (
        categories.map((file, fileIndex) => (
          <Accordion disableGutters sx={{ backgroundColor: color }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}
            >
              <Typography variant="h6">{file.germanTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: secondaryColor }}
            ></AccordionDetails>
          </Accordion>
        ))
      ) : (
        <List>
          {categories.map((file, fileIndex) => (
            <ListItem
              key={fileIndex}
              sx={{
                listStyle: "outside",
                textDecoration: "none",
                width: "fit-content",
              }}
              component="a"
              href={file.fileUrl}
              download
            >
              <ListItemText
                sx={{
                  flex: "none",
                  color: theme.palette.mode === "dark" ? "white" : "black",
                }}
                primary={file.germanTitle}
              />
              <IconButton
                edge="end"
                aria-label="download"
                href={file.fileUrl}
                download
              >
                <GetAppIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

// Accordion component for each section
const renderAccordion = (
  title: string,
  categories: FileCategoryKunden[],
  theme: Theme,
  type: "nested" | "normal",
  color: string,
  secondaryColor: string
) => (
  <Accordion disableGutters sx={{ backgroundColor: color }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}>
      <Typography variant="h5">{title}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ backgroundColor: secondaryColor }}>
      {renderFileCategoryList(categories, theme, type, color, secondaryColor)}
    </AccordionDetails>
  </Accordion>
);

const KundenDownloads: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Paper elevation={5}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={colors.primary[400]}
        borderRadius="5px"
        marginTop="20px"
      >
        <Box
          ml={{ md: "0px", xs: "0px" }}
          mt="20px"
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            minHeight: "93vh",
            justifyContent: { md: "space-around", xs: "flex-start" },
            gap: "30px",
            padding: "20px",
          }}
        >
          <Box sx={{ flex: { md: 0.5, xs: 0 } }}>
            <Typography variant="h4" gutterBottom>
              Industriewasser
            </Typography>
            {renderAccordion(
              "Ausschreibungstexte",
              industriwasserCategories,
              theme,
              "nested",
              colors.primary[400],
              colors.primary[500]
            )}
            {renderAccordion(
              "Broschüren",
              brochureSection,
              theme,
              "normal",
              colors.primary[400],
              colors.primary[500]
            )}
          </Box>
          <Box sx={{ flex: { md: 0.5, xs: 0 } }}>
            <Typography variant="h4" sx={{ mb: "6px" }}>
              Coracon
            </Typography>
            {renderAccordion(
              "Ausschreibungstexte",
              coraconCategories,
              theme,
              "nested",
              colors.primary[400],
              colors.primary[500]
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default KundenDownloads;
