import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "title",
    headerName: "Titel",
    flex: 1.5,
  },
  {
    field: "type",
    headerName: "Typ",
    flex: 0.5,
  },
  {
    field: "sent_at",
    headerName: "Gesendet am",
    flex: 1,
  },
];
